import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882aef26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-cards" }
const _hoisted_2 = { class: "action-info" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "action-name" }
const _hoisted_6 = {
  key: 0,
  class: "action-description"
}
const _hoisted_7 = { class: "action-date" }
const _hoisted_8 = { class: "action-controls" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "action-notice"
}
const _hoisted_11 = { class: "wrapper-cards-mobile" }
const _hoisted_12 = { class: "action-info" }
const _hoisted_13 = { class: "header" }
const _hoisted_14 = { class: "action-name" }
const _hoisted_15 = { class: "body" }
const _hoisted_16 = {
  key: 0,
  class: "action-description"
}
const _hoisted_17 = { class: "action-date" }
const _hoisted_18 = { class: "action-controls" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 0,
  class: "action-notice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.normalizedActions, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "card card-upcoming"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["upcoming-icon", item.icon])
              }, null, 2)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
              (item.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.date), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (item.action)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "button primary default action-btn",
                  onClick: item.action.handler
                }, _toDisplayString(item.action.text), 9, _hoisted_9))
              : _createCommentVNode("", true)
          ]),
          (item.notice)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.notice), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.normalizedActions, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "card card-upcoming"
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                class: _normalizeClass(["upcoming-icon", item.icon])
              }, null, 2),
              _createElementVNode("div", _hoisted_14, _toDisplayString(item.title), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              (item.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, _toDisplayString(item.date), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            (item.action)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "button primary default action-btn",
                  onClick: item.action.handler
                }, _toDisplayString(item.action.text), 9, _hoisted_19))
              : _createCommentVNode("", true)
          ]),
          (item.notice)
            ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(item.notice), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}